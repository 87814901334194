import React from 'react';
import PropTypes from 'prop-types';

const Insight = ({ icon, title, body }) => (
  <div className='flex mb-6 items-start'>
    <img src={icon} alt={title} className='h-10 mr-4' />

    <div>
      <h3 className='font-soft font-semibold text-yellow text-xl leading-snug my-2'>
        {title}
      </h3>

      <p className='font-sans font-normal text-white text-sm leading-snug'>
        {body}
      </p>
    </div>
  </div>
);

Insight.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
};

Insight.defaultProps = {
  icon: '',
  title: '',
  body: '',
};

export default Insight;
