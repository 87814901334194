import React from 'react';
import ReactGA from 'react-ga';
import Modal from './doctor-referral/referral-modal';
import backgroundImage from '../../images/pattern-lines-yellow.svg';

class DoctorReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(state => ({
      modalVisible: !state.modalVisible,
    }));
  }

  render() {
    const { modalVisible } = this.state;

    return (

      <div
        className='bg-yellow'
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'auto 70%',
        }}
      >
        <div className='container mx-auto pt-16 lg:pt-32 pb-16 px-8'>

          <div className='text-center'>
            <button
              type='button'
              className='button-blue mb-4'
              onClick={() => {
                ReactGA.event({
                  category: 'CTAs',
                  action: 'Click',
                  label: 'Doctor not listed? LET US KNOW',
                });
                this.toggleModal();
              }}
            >
              Doctor not listed? Let us know!
            </button>

            <h4 className='lg:w-3/4 mx-auto font-soft font-semibold text-black'>
              We’ll keep you up to date when we add new doctors.
            </h4>
          </div>

        </div>
        <Modal
          modalVisible={modalVisible}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

export default DoctorReferral;
