import React from 'react';
import PropTypes from 'prop-types';

const PrivacyItem = ({ icon, body }) => (
  <div className='flex mb-4 items-start'>
    <img src={icon} alt='privacy' className='h-16 mr-4' />

    <h3 className='font-soft font-normal text-grey text-normal leading-snug'>
      {body}
    </h3>
  </div>
);

PrivacyItem.propTypes = {
  icon: PropTypes.string,
  body: PropTypes.string,
};

PrivacyItem.defaultProps = {
  icon: '',
  body: '',
};

export default PrivacyItem;
