import React from 'react';
import PropTypes from 'prop-types';

const Doctor = ({ doctor, clinic, phone, formattedPhone }) => (
  <div className='bg-blue-100 p-4 w-full'>
    <h5 className='font-soft font-semibold text-blue text-xl'>
      {doctor}
    </h5>

    <p className='font-soft text-blue-900 text-sm'>
      {clinic}
    </p>

    <a href={`tel:${phone}`} className='font-soft font-semibold text-blue-900 text-sm'>
      {formattedPhone}
    </a>
  </div>
);

Doctor.propTypes = {
  doctor: PropTypes.string,
  clinic: PropTypes.string,
  phone: PropTypes.string,
  formattedPhone: PropTypes.string,
};

Doctor.defaultProps = {
  doctor: '',
  clinic: '',
  phone: '',
  formattedPhone: '',
};

export default Doctor;
