import React from 'react';
import Benefit from './put-nl-first/benefit';
import nlIcon from '../../images/nl.svg';
import informIcon from '../../images/inform.svg';
import developIcon from '../../images/develop.svg';
import precisionIcon from '../../images/precision.svg';
import diseasesIcon from '../../images/diseases.svg';
import populationIcon from '../../images/population.svg';

const PutNlFirst = () => (
  <div className='bg-white'>
    <div className='container mx-auto py-16 px-8 flex flex-col lg:flex-row'>

      <div className='lg:w-1/3 lg:pr-8'>
        <h2 className='font-sans font-bold text-blue text-5xl leading-very-tight mb-4'>
          Putting
          <br />
          NL first
        </h2>

        <h4 className='font-soft font-semibold text-black text-lg leading-snug mb-4'>
          Sequence Bio is committed to benefiting the lives of individuals, families, and communities in Newfoundland and Labrador for generations to come.
        </h4>

        <p className='font-sans font-normal text-grey leading-snug'>
          We’ll share any discoveries from the NL Genome Project with researchers, policy makers, and doctors across the province, and return genetic findings to participants. By working together, we believe that Newfoundlanders and Labradorians can change lives at home and around the world.
        </p>
      </div>

      <div className='flex flex-col lg:w-2/3 mt-8 lg:mt-32'>
        <div className='flex flex-col lg:flex-row flex-wrap justify-between lg:mb-8'>
          <div className='flex-1 mr-8'>
            <Benefit
              icon={nlIcon}
              title='Understand the genetic makeup of Newfoundland and Labrador'
            />
          </div>

          <div className='flex-1'>
            <Benefit
              icon={informIcon}
              title='Inform better healthcare choices by returning findings to participants'
            />
          </div>
        </div>

        <div className='flex flex-col lg:flex-row flex-wrap justify-between lg:mb-8'>
          <div className='flex-1 mr-8'>
            <Benefit
              icon={developIcon}
              title='Develop better, safer and more targeted medicines'
            />
          </div>

          <div className='flex-1'>
            <Benefit
              icon={precisionIcon}
              title='Integrate precision medicine into our local healthcare system'
            />
          </div>
        </div>

        <div className='flex flex-col lg:flex-row flex-wrap justify-between lg:mb-8'>
          <div className='flex-1 mr-8'>
            <Benefit
              icon={diseasesIcon}
              title='Improve how we treat and prevent diseases'
            />
          </div>

          <div className='flex-1'>
            <Benefit
              icon={populationIcon}
              title='Design a larger research project in Newfoundland and Labrador'
            />
          </div>
        </div>
      </div>

    </div>
  </div>
);

export default PutNlFirst;