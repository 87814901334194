import React from 'react';
import Doctor from './enrollment-criteria/doctor';
import arrow from '../../images/arrow.svg';

const EnrollmentCriteria = () => (
  <div className='bg-white lg:bg-gradient-b-grey-100-to-transparent lg:pt-24' id='take_part'>
    <div className='container mx-auto pt-16 lg:pt-12 pb-16 px-8 lg:px-16 bg-white lg:-mb-16 '>
      <h2 className='font-sans font-bold text-blue-500 text-4xl lg:text-5xl leading-very-tight mb-6'>
        Can I take part?
      </h2>
      <div className='flex flex-col lg:flex-row'>
        <div className='lg:w-1/2 lg:pr-8'>
          <h4 className='font-soft font-semibold text-black text-lg leading-snug mb-4'>
          If you meet the study’s eligibility criteria and are a patient of a participating doctor, you can volunteer for the NL Genome Project!
          </h4>

          <p className='font-sans font-normal text-black'>
          The following doctors are participating in the NL Genome Project. Your doctor has to
          be listed to take part. If you are a patient of theirs, contact their office to get started!
          </p>
        </div>

        <div className='lg:w-1/2 lg:pl-8'>
          <h4 className='font-soft font-semibold text-black text-lg leading-snug mb-4'>
            Eligibility criteria
          </h4>
          <ul className='font-sans font-normal text-black mb-4 lg:mt-6 leading-snug'>
            <li className='flex items-start mb-1'>
              <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
              <span className='flex-1'>
                18 years of age or older
              </span>
            </li>
            <li className='flex items-start mb-1'>
              <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
              <span className='flex-1'>
                Have a valid MCP #
              </span>
            </li>
            <li className='flex items-start mb-1'>
              <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
              <span className='flex-1'>
                Able to provide written or electronic informed consent
              </span>
            </li>
            <li className='flex items-start mb-1'>
              <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
              <span className='flex-1'>
                Willing to be re-contacted
              </span>
            </li>
            <li className='flex items-start mb-1'>
              <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
              <span className='flex-1'>
                Willing to have your coded data shared with researchers both inside and outside of Canada
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className='container mx-auto pb-16 lg:-mb-16 px-8 lg:px-16 bg-white relative'>
      <h4 className='font-soft font-semibold text-blue text-xl leading-snug mb-4 mt-6'>
        Participating Doctors
      </h4>

      <ul className='flex flex-col lg:flex-row flex-wrap justify-between'>
        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. David Brentnall'
            clinic='Family Medical Practice/Walk-In Clinic, Paradise'
            phone='+17097818715'
            formattedPhone='709.781.8715'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor="Dr. Dennis O'Keefe"
            clinic='Commonwealth Medical Clinic'
            phone='+17093647555'
            formattedPhone='709.364.7555'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Jacqueline Elliott'
            clinic='Port Blandford Medical Clinic, Port Blandford'
            phone='+17095432490'
            formattedPhone='709.543.2490'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Emily Godec'
            clinic="Saltwater Family Wellness, St. John's"
            phone='+17095521122'
            formattedPhone='709.552.1122'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Tony Gabriel'
            clinic='Gander Medical Clinic, Gander'
            phone='+17092567101'
            formattedPhone='709.256.7101'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Lynette Power'
            clinic='Burin Medical Clinic, Burin'
            phone='+17098915005'
            formattedPhone='709.891.5005'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Chris Peddle'
            clinic='TC Medical Clinic, Carbonear'
            phone='+17095965136'
            formattedPhone='709.596.5136'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. John Janes'
            clinic='Commonwealth Medical Clinic, Mount Pearl'
            phone='+17093647555'
            formattedPhone='709.364.7555'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Jamison Mercer'
            clinic='Paradise Wellness Clinic, Paradise'
            phone='+17097824123'
            formattedPhone='709.782.4123'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Wendy Graham'
            clinic='Dr. Wendy Graham PMC Limited, Channel-Port aux Basques'
            phone='+17096957775'
            formattedPhone='709.695.7775'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Megan Dawe'
            clinic='Paradise Wellness Clinic, Paradise'
            phone='+17097824123'
            formattedPhone='709.782.4123'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Lesley Manning'
            clinic='Paradise Wellness Clinic, Paradise'
            phone='+17097824123'
            formattedPhone='709.782.4123'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Chris Dicks'
            clinic='Gander Medical Clinic'
            phone='+17092567101'
            formattedPhone='709.256.7101'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Emmanuel Jones'
            clinic='Clarke’s Beach Medical Clinic'
            phone='+17097866611'
            formattedPhone='709.786.6611'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Nancy Barker'
            clinic='Pasadena Medical Clinic'
            phone='+17093880820'
            formattedPhone='709.388.0820'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Lorena Power'
            clinic='Pasadena Medical Clinic'
            phone='+17093880870'
            formattedPhone='709.388.0870'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Ahmad Ibrahim'
            clinic='Gander Medical Clinic'
            phone='+17092567101'
            formattedPhone='709.256.7101'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Andrew Hutton'
            clinic='Dr. Hutton Family Practice'
            phone='+17097397444'
            formattedPhone='709.739.7444'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Paula Pye'
            clinic='Family Medical Clinic'
            phone='+17094665400'
            formattedPhone='709.466.5400'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Blaine Pearce'
            clinic='Family Medical Clinic'
            phone='+17094665400'
            formattedPhone='709.466.5400'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Gert Nel'
            clinic='Dr. A. A. Wilkinson Memorial Health Centre'
            phone='+17095872200'
            formattedPhone='709.587.2200'
          />
        </li>
  
        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Stephen Lee'
            clinic='Riverdale Medical Clinic'
            phone='+17092403020'
            formattedPhone='709.240.3020'
          />
        </li>
  
        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Jason Efford'
            clinic='Avalon Medical'
            phone='+17097860091'
            formattedPhone='709.786.0091'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. William Moulton'
            clinic='Medical Clinic, Marystown'
            phone='+17092792631'
            formattedPhone='709.279.2631'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Todd Young'
            clinic='Main Street Medical Clinic, Springdale'
            phone='+18775784861'
            formattedPhone='1.877.578.4861'
          />
        </li>

        <li className='pr-4 flex flex-col text-center items-center justify-center mb-4 w-full lg:w-1/2'>
          <Doctor
            doctor='Dr. Terry Maher'
            clinic='Deer Lake Medical Clinic, Deer Lake'
            phone='+17096355150'
            formattedPhone='709.635.5150'
          />
        </li>
      </ul>
    </div>
  </div>
);

export default EnrollmentCriteria;
