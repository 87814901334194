import React from 'react';
import uniqueIcon from '../../images/Icons - NL unique.svg';
import diseaseIcon from '../../images/Icons - NL Disease.svg';
import ehrIcon from '../../images/icon-ehr.svg';
import Insight from './homegrown-opportunity/insight';

const HomegrownOpportunity = () => (
  <div className='home-homegrown-hero'>
    <div className='container mx-auto py-16 px-8 flex flex-row-reverse'>
      <div className='lg:ml-8 lg:w-1/2'>
        <h2 className='font-sans font-bold text-yellow text-4xl lg:text-5xl leading-very-tight mb-4'>
          A homegrown opportunity
        </h2>

        <h4 className='font-soft font-semibold text-white text-lg leading-snug mb-6'>
          Newfoundland’s unique history and genetic makeup may make it easier to identify some underlying differences that may affect disease and health.
        </h4>

        <Insight
          icon={uniqueIcon}
          title='Unique population'
          body='Many common ancestors, large families and little in-migration have contributed to our unique genetic makeup.'
        />

        <Insight
          icon={diseaseIcon}
          title='High incidence of disease'
          body='Our population is associated with high rates of many inherited disorders and complex diseases, including some of the highest rates of type 1 diabetes and familial colorectal cancer in the world.'
        />

        <Insight
          icon={ehrIcon}
          title='Comprehensive health records'
          body='By linking health information to genetics, we can get a fuller picture of health and disease that may help unlock deeper insights.'
        />
      </div>
    </div>
  </div>
);

export default HomegrownOpportunity;
