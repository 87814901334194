import React from 'react';
import PrivacyItem from './privacy/privacy-item';
import privacyPolicyImage from '../../images/privacy.png';
import privacyLockIcon from '../../images/privacy-lock.svg';
import privacyCastleIcon from '../../images/privacy-castle.svg';

const Privacy = () => (
  <div className='bg-white'>
    <div className='container mx-auto py-16 px-8 flex flex-col lg:flex-row'>

      <div className='text-center lg:w-1/3 lg:pr-8 flex-1 mb-12'>
        <a href='/privacy'>
          <img
            src={privacyPolicyImage}
            alt='Privacy is our policy'
            className='lg:h-40'
            style={{
              maxHeight: '10rem',
            }}
          />
        </a>
      </div>

      <div className='lg:w-1/2 flex-1'>
        <h4 className='font-soft font-semibold text-blue text-lg leading-snug mb-8'>
          All of us at the NL Genome Project are committed to protecting the information you choose to share with us. We will not sell your information. We promise to be transparent about our information policies and practices, and will only use your information with your explicit permission.
        </h4>

        <div className='mb-8'>
          <PrivacyItem
            icon={privacyLockIcon}
            body='Information that identifies you, like your name or birthday, is not used for research and is locked away in a separate encrypted database. Instead, you will only be identified by a unique Study Code when research is performed.'
          />
        </div>

        <div>
          <PrivacyItem
            icon={privacyCastleIcon}
            body=' All data is protected by the latest encryption and security protocols, and stored here in Canada in best-in-industry data centres.'
          />
        </div>
      </div>

    </div>
  </div>
);

export default Privacy;
