import React from 'react';
import PropTypes from 'prop-types';
import ReferralForm from './referral-form';
import closeIcon from '../../../images/close.svg';
import borderPattern from '../../../images/pattern-lines-yellow.svg';


const ReferralModal = ({ modalVisible, toggleModal }) => (
  <div
    style={{
      background: 'rgba(0,0,0,0.2)',
      boxSizing: 'border-box',
    }}
    className={`${
      modalVisible ? 'visible' : 'hidden'
    } fixed z-10 left-0 top-0 flex items-center justify-center w-screen h-screen overflow-y-auto p-4`}
  >
    <div
      className='m-auto p-6 lg:p-8 lg:w-2/5 bg-yellow rounded-lg shadow-xl'
      style={{
        backgroundImage: `url(${borderPattern})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto 30%',
      }}
    >
      <div className='p-8 bg-white rounded-lg relative h-auto'>
        <button
          type='button'
          className='absolute right-0 top-0 mt-2 mr-2 p-3 cursor-pointer outline-none rounded-full hover:bg-grey-100 focus:outline-none active:bg-grey-200'
          onClick={() => {
            toggleModal();
          }}
        >
          <img src={closeIcon} alt='Close' className='w-3' />
        </button>

        <h2 className='font-sans font-bold text-blue text-2xl lg:text-4xl leading-none mb-6'>
          We hope to add more doctors in the future!
        </h2>

        <p className='font-sans text-sm lg:text-base text-black mb-4 leading-snug'>
          If you’d like, let us know your doctor’s name so we can keep you updated on their participation status, if any.
        </p>

        <ReferralForm />
      </div>
    </div>
  </div>
);

ReferralModal.propTypes = {
  modalVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
};

ReferralModal.defaultProps = {
  modalVisible: '',
  toggleModal: () => {},
};

export default ReferralModal;
