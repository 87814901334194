import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/home/hero';
import HowItWorks from '../components/home/how-it-works';
import EnrollmentCriteria from '../components/home/enrollment-criteria';
import DoctorReferral from '../components/home/doctor-referral';
import PutNlFirst from '../components/home/put-nl-first';
import HomegrownOpportunity from '../components/home/homegrown-opportunity';
import Privacy from '../components/home/privacy';
import Questions from '../components/home/questions';

const IndexPage = () => (
  <Layout>
    <SEO
      title='Welcome to the NL Genome Project'
      description="We're researching the unique genetic makeup of Newfoundland & Labrador with an aim to find better, safer medicines and improve how we treat disease."
    />
    <Hero />
    <HowItWorks />
    <EnrollmentCriteria />
    <DoctorReferral />
    <PutNlFirst />
    <HomegrownOpportunity />
    <Privacy />
    <Questions />
  </Layout>
);

export default IndexPage;
