import React from 'react';
import PropTypes from 'prop-types';

const Benefit = ({ icon, title }) => (
  <div className='flex mb-4 items-center'>
    <img src={icon} alt={title} className='h-16 mr-4' />

    <h3 className='font-soft font-semibold text-black text-normal leading-snug'>
      {title}
    </h3>
  </div>
);

Benefit.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
};

Benefit.defaultProps = {
  icon: '',
  title: '',
};

export default Benefit;