import React from 'react';
import ReactGA from 'react-ga';
import backgroundImage from '../../images/pattern-dna-blue.svg';

const Questions = () => (
  <div
    className='bg-blue'
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'repeat',
      backgroundSize: 'auto 55%',
    }}
  >

    <div className='container mx-auto py-16 px-8'>
      <div className='text-center'>
        <a
          href='/faq'
          className='button'
          onClick={() => {
            ReactGA.event({
              category: 'CTAs',
              action: 'Click',
              label: "More questions? We’ve got you covered",
            });
          }}
        >
          More questions? We’ve got you covered
        </a>
      </div>
    </div>
  </div>
);

export default Questions;
