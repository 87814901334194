import React from 'react';
import PropTypes from 'prop-types';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const MailingListForm = ({ status, message, onValidated }) => {
  let email;
  let firstName;
  let lastName;
  let doctor;
  const form = React.createRef();

  const encodeData = data => Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');

  const submitFormToNetlify = () => {
    const xhr = new XMLHttpRequest();
    const data = encodeData({
      'form-name': 'doctor',
      doctor: doctor.value,
    });

    xhr.open('POST', '/', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(data);
  };

  const submit = (e) => {
    e.preventDefault();

    // The group field is necessary for segmentation on mailchimp
    onValidated({
      EMAIL: email.value,
      FNAME: firstName.value,
      LNAME: lastName.value,
      'group[18973][1]': 1,
    });

    submitFormToNetlify();

    form.current.reset();
  };

  return (
    <form ref={form} onSubmit={submit} name='doctor' data-netlify='true'>
      <input type='hidden' name='form-name' value='doctor' />

      <div className='mb-8'>
        <input
          ref={node => (doctor = node)}
          type='text'
          name='doctor'
          placeholder="Your doctor's name"
          className='font-sans bg-grey-100 py-3 px-4 rounded w-full border-2 border-transparent outline-none focus:bg-white focus:border-blue'
        />
      </div>

      <p className='font-sans font-semibold text-sm text-black mb-4 leading-snug'>
        Enter your name and email below to sign up for our mailing list.
      </p>

      <div className='flex'>
        <div className='flex-1 mr-4 mb-4'>
          <input
            ref={node => (firstName = node)}
            type='text'
            name='first-name'
            placeholder='First name'
            className='font-sans bg-grey-100 py-3 px-4 rounded w-full border-2 border-transparent outline-none focus:bg-white focus:border-blue'
          />
        </div>

        <div className='flex-1 mb-4'>
          <input
            ref={node => (lastName = node)}
            type='text'
            name='last-name'
            placeholder='Last name'
            className='font-sans bg-grey-100 py-3 px-4 rounded w-full border-2 border-transparent outline-none focus:bg-white focus:border-blue'
          />
        </div>
      </div>

      <div className='mb-4'>
        <input
          ref={node => (email = node)}
          type='email'
          required
          name='email'
          placeholder='Email*'
          className='font-sans bg-grey-100 py-3 px-4 rounded w-full border-2 border-transparent outline-none focus:bg-white focus:border-blue'
        />
      </div>

      <p className='font-sans font-normal text-xs text-grey italic mb-4 leading-snug'>
        Your doctor's name will not be linked to your name or email.
      </p>

      <div className='flex items-center text-red mt-8'>
        <button type='submit' className='button-blue rounded flex-none mr-4'>
          Join
        </button>

        <div className='flex-grow font-soft font-semibold text-grey text-sm leading-snug'>
          {status === 'sending' && (
            <div>
              Joining...
            </div>
          )}
          {status === 'error' && (
            <div
              className='text-red-700'
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === 'success' && (
            <div className='text-green-600'>
              {message}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const MailChimpForm = () => {
  const url = 'https://nlgenomeproject.us11.list-manage.com/subscribe/post?u=85f746b489785f126d2584b41&amp;id=647b3f2bac';

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <MailingListForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  );
};

MailingListForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func,
};

MailingListForm.defaultProps = {
  status: '',
  message: '',
  onValidated: () => {},
};

export default MailChimpForm;
