import React from 'react';
import HowItWorksStep from './how-it-works/step';
import consentIcon from '../../images/icon-consent.svg';
import sampleIcon from '../../images/icon-sample.svg';
import dnaIcon from '../../images/icon-dna.svg';
import findingsIcon from '../../images/icon-findings.svg';

const HowItWorks = () => (
  <div className='bg-blue-500'>
    <div className='container mx-auto py-16 px-8'>

      <div className='text-center mb-12'>
        <h2 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Here’s how it all works
        </h2>

        <h4 className='lg:w-3/4 mx-auto font-soft font-semibold text-yellow text-lg leading-snug mb-4'>
          The NL Genome Project is studying the DNA, medical records, and health information of 10,000 volunteers in Newfoundland and Labrador in this initial pilot phase.
        </h4>
      </div>

      <div className='flex flex-col lg:justify-between lg:flex-row'>
        <div className='text-center flex-1 lg:mr-8'>
          <HowItWorksStep
            icon={consentIcon}
            title='Consent to Join the Study'
            body='Once you’ve decided to take part, you will complete and sign the consent form to enroll in the NL Genome Project and grant permission for us to use your medical records for research. Then, you’ll provide your contact information and fill out a health questionnaire.'
          />
        </div>

        <div className='text-center flex-1 lg:mr-8'>
          <HowItWorksStep
            icon={sampleIcon}
            title='Provide a Saliva Sample'
            body='Provide a saliva sample (less than half a teaspoon) by spitting in a tube. We’ll extract DNA from your saliva and analyze it using techniques like genotyping and sequencing.'
          />
        </div>

        <div className='text-center flex-1 lg:mr-8'>
          <HowItWorksStep
            icon={dnaIcon}
            title="We'll Study Your DNA"
            body="We'll combine and analyze the DNA, medical records, and health information from all our participants and look for patterns and genetic changes that could help us better understand health and disease."
          />
        </div>

        <div className='text-center flex-1'>
          <HowItWorksStep
            icon={findingsIcon}
            title='Return Findings and Stay in Touch'
            body='Research-grade findings will be returned to your doctor to discuss with you and our genetic counsellor, if you choose to receive them. Personal traits will be shared directly with you.'
          />
        </div>
      </div>

    </div>
  </div>
);

export default HowItWorks;
